// @flow
import * as React from 'react';

import Tool, { type ToolShape } from './Tool';

type Props = {
  name: string,
  title: string,
  tools: Array<ToolShape>,
  locale: string,
  hamburgerMenu: React.Node,
  search: React.Node,
  isDesktop: boolean,
};

const Toolbar = (props: Props) => (
  <nav
    className={`c-toolbar ${
      props.locale.selected === 'en-US' ? 'c-toolbar--usOnly' : ''
    }`}
    role="navigation"
    aria-labelledby={props.name}
  >
    <h1 className="c-toolbar__title u-hidden" id={props.name}>
      {props.title}
    </h1>
    <ul
      className={`c-toolbar__items ${
        props.locale.selected === 'en-US' ? 'c-toolbar__items--usOnly' : ''
      }`}
    >
      {props.hamburgerMenu && props.hamburgerMenu}
      {props.search && props.search}
      {props.tools.map((tool, index) => (
        <Tool
          tool={tool}
          key={index}
          locale={props.locale}
          isDesktop={props.isDesktop}
        />
      ))}
    </ul>
  </nav>
);

Toolbar.defaultProps = {
  name: 'Toolbar',
  title: 'Toolbar',
  tools: [],
};

export default Toolbar;
