/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import core from '@bravissimolabs/website-core';
import { reduxForm } from 'redux-form';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { useCookies } from 'react-cookie';
import _ from 'lodash';

import Header from 'brastrap/global/header/Header';
import Navigation from 'brastrap/global/navigation/Navigation';
import MegaMenus from 'brastrap/global/mega-menus/MegaMenus';
import DrawerNavigation from 'brastrap/global/drawer-navigation/DrawerNavigation';
import DrawerBag from 'brastrap/global/drawer-bag/DrawerBag';
import Toolbar from 'brastrap/common/toolbar/Toolbar';
import PopoverBag from 'brastrap/transactional/bag/PopoverBag';
import EditorialContainer from 'brastrap/editorial/editorial-container/EditorialContainer';
import LocaleSwitcher from 'brastrap/global/locale-switcher/LocaleSwitcher';
import Carousel from 'brastrap/common/carousel/Carousel';
import BodyNav from '../components/BodyNav';
import Bag from './BagContainer';

import { apiLogout } from '../actions/auth';
import * as navigationActions from '../actions/masthead/navigation';
import { openLayer, closeLayer } from '../actions/layer';
import * as megaMenuActions from '../actions/masthead/mega-menu';
import { onNavPress } from '../actions/masthead';
import {
  hideBag,
  openPopoverBag,
  receiveTools,
  receiveLinks,
  receiveTopBarText,
} from '../actions/masthead/header';
import { receiveContainers } from '../actions/masthead/containers';
import { receiveSavedItemsList } from '../actions/saved-items';

import { CLOSE_POPOVER_BAG_TIMER } from '../constants/timers';
import { cancelTimer } from '../actions/timers';

import { getExpirationDate } from '../utils/utils.js';
import { MY_ACCOUNT, SHOP, SEARCH } from '../../brastrap/components/constants';

const messages = defineMessages({
  Account: { id: 'tool.account', defaultMessage: 'My Account' },
  Bag: { id: 'tool.bag', defaultMessage: 'My Bag' },
  'delivery-addresses': {
    id: 'tool.delivery-addresses',
    defaultMessage: 'Delivery addresses',
  },
  'saved-items': { id: 'tool.saved-items', defaultMessage: 'Saved Items' },
  Login: { id: 'tool.login', defaultMessage: 'Login' },
  logout: { id: 'button.logout', defaultMessage: 'Log out' },
  'loyalty-points': { id: 'tool.loyalty', defaultMessage: 'Bravellous Points' },
  'my-orders': {
    id: 'tool.my-orders',
    defaultMessage: 'My Orders',
  },
  returns: {
    id: 'tool.returns',
    defaultMessage: 'Returns',
  },
  'orders-returns': {
    id: 'tool.orders-returns',
    defaultMessage: 'Orders & Returns',
  },
  'payment-methods': {
    id: 'tool.payment-methods',
    defaultMessage: 'Payment methods',
  },
  'personal-info': {
    id: 'tool.personal-info',
    defaultMessage: 'Personal info',
  },
});

const localeLabels = defineMessages({
  'en-GB': { id: 'locale.en-GB', defaultMessage: 'UK/Rest of World' },
  'en-US': { id: 'locale.en-US', defaultMessage: 'USA' },
});

const debouncedSearch = _.debounce(
  (searchPhrase, dispatch) =>
    dispatch(navigationActions.apiSuggestedSearch(searchPhrase)),
  500
);

/**
 * @param {object} event
 * @param {function} dispatch
 * @returns {*}
 */
const onSearchKeyUp = (event, dispatch) => {
  const searchPhrase = event.currentTarget.value;
  return debouncedSearch(searchPhrase, dispatch);
};

const updateLogoutOption = (accountTool, isAuthenticated, onLogout, formatMessage, property) => {
  const logoutOption = _.find(accountTool[property], option => option.id === 'logout');

  if (isAuthenticated && !logoutOption) {
    const logout = {
      id: 'logout',
      label: formatMessage(messages.logout),
      onClick: onLogout,
    };
    accountTool[property].push(logout);
  } else if (!isAuthenticated && logoutOption) {
    accountTool[property] = accountTool[property].filter(option => option.id !== 'logout');
  } else if (isAuthenticated && logoutOption && !logoutOption.onClick) {
    logoutOption.onClick = onLogout;
  }
};

/**
 * Update the 'Log in' or 'Log out' link in the header depending on the user's authenticated state.
 * @param {Array} tools all the header's tools
 * @param {boolean} isAuthenticated whether the user is authenticated
 * @param {Function} onLogout a handler to trigger a logout
 * @param {Function} formatMessage
 */
const updateAuthLink = (tools, isAuthenticated, onLogout, formatMessage) => {
  const accountTool = _.find(tools, tool => tool.id === 'Account');
  updateLogoutOption(accountTool, isAuthenticated, onLogout, formatMessage, 'dropdown');
  updateLogoutOption(accountTool, isAuthenticated, onLogout, formatMessage, 'myAccountTabItems');
};

const updateTools = (
  locale,
  isAuthenticated,
  dispatch,
  savedItemsCount,
  bagItemsCount,
  isReturnsPortalEnabled,
  isDesktop
) => {
  const dropDownItems = [
    {
      id: 'personal-info',
      url: '/account/personal',
    },
    {
      id: 'delivery-addresses',
      url: '/account/delivery-addresses',
    },
    {
      id: 'payment-methods',
      url: '/account/payment-methods',
    },
  ];

  if (locale === 'en-GB') {
    dropDownItems.splice(4, 0, {
      id: 'loyalty-points',
      url: '/account/bravellous-points',
    });
  }

  if (locale === 'en-US' && isReturnsPortalEnabled) {
    dropDownItems.splice(
      1,
      0,
      {
        id: 'my-orders',
        url: '/account/orders-and-returns',
      },
      {
        id: 'returns',
        url: '/returns/start-return',
      }
    );
  } else {
    dropDownItems.splice(1, 0, {
      id: 'orders-returns',
      url: '/account/orders-and-returns',
    });
  }

  if (isAuthenticated) {
    dropDownItems.push({
      id: 'logout',
      // onClick is set on the client - see above
    });
  }

  const accountTool = {
    id: 'Account',
    icon: 'account--large',
    labelled: 'after',
    dropdown: locale === 'en-GB' || isDesktop ? dropDownItems : [],
    myAccountTabItems: dropDownItems,
  };
  const savedItemsAndBagTools = [
    {
      id: 'saved-items',
      url: '/saved-items/',
      icon: 'toolbar--saved-items--large',
      labelled: 'after',
      count: savedItemsCount,
      countContext: {
        one: 'item',
        other: 'items',
      },
    },
    {
      id: 'Bag',
      url: '/bag/',
      icon: 'toolbar--bag--large',
      menu: 'bag',
      labelled: 'after',
      count: bagItemsCount,
      countContext: {
        one: 'item',
        other: 'items',
      },
    },
  ];

  if (locale === 'en-US' && !isDesktop) {
    accountTool.onClick = () => {
      dispatch(openLayer(MY_ACCOUNT));
    };
  }

  let tools = [{ ...accountTool }];
  if (locale === 'en-US') {
    tools = tools.concat(savedItemsAndBagTools.reverse());
  } else {
    tools = tools.concat(savedItemsAndBagTools);
  }

  dispatch(receiveTools(tools));
};

/**
 * Localise all the tool labels.
 *
 * @param {Array} tools
 * @param {Function} formatMessage
 * @returns {Array}
 */
const localiseTools = (tools, formatMessage) =>
  tools.map(t => {
    const tool = { ...t };
    tool.label = formatMessage(messages[t.id]);

    if (tool.dropdown && tool.dropdown.length) {
      tool.dropdown = localiseTools(tool.dropdown, formatMessage);
    }

    if (tool.myAccountTabItems && tool.myAccountTabItems.length) {
      tool.myAccountTabItems = localiseTools(
        tool.myAccountTabItems,
        formatMessage
      );
    }
    return tool;
  });

/**
 * @param {Object} props
 * @returns {XML}
 * @constructor
 */
const Masthead = props => {
  let bodyNavMenuId = null;
  const displayDrawer = !props.isDesktop;
  const {
    isAuthenticated,
    savedItemsCount,
    bagItemsCount,
    isReturnsPortalEnabled,
  } = props;
  const [cookies, setCookie] = useCookies(['']);
  const [topBarItems, setTopBarItems] = useState(null);

  useEffect(() => {
    const eventTime = Math.floor(new Date().getTime() / 1000);
    if (!cookies._fbc) {
      const fbclid = new URLSearchParams(window.location.search).get('fbclid');
      if (fbclid) {
        setCookie('_fbc', `fb.1.${eventTime}.${fbclid}`, {
          expires: getExpirationDate(7),
        });
      }
    }
    props.onUpdateTools(
      props.locale.selected,
      isAuthenticated,
      savedItemsCount,
      bagItemsCount,
      isReturnsPortalEnabled,
      props.isDesktop
    );
  }, [isAuthenticated, props.locale.selected]);

  useEffect(() => {
    props.header.topBarText &&
      setTopBarItems(
        props.header.topBarText.map(item => (
          <div>
            <Item link={item.link} size={item.size}>
              {item.content}
            </Item>
          </div>
        ))
      );

    if (window && document && props.locale.selected === 'en-US') {
      window.onscroll = () => {
        const navSection = document.getElementById('nav-section');
        const page = document.getElementById('c-page');
        const logo = document.getElementById('header-logo');
        const sticky = navSection.offsetTop;
        if (window.pageYOffset > sticky) {
          navSection.classList.add(
            'c-masthead__navigation-section--sticky-usOnly'
          );
          page.classList.add('c-page--sticky-nav-padding');
          logo.classList.add('c-header__logo--sticky-usOnly');
        } else if (window.pageYOffset < sticky || window.pageYOffset === 0) {
          navSection.classList.remove(
            'c-masthead__navigation-section--sticky-usOnly'
          );
          page.classList.remove('c-page--sticky-nav-padding');
          logo.classList.remove('c-header__logo--sticky-usOnly');
        }
      };
    }
  }, []);

  const navigationProps = {
    ...props.navigation,
    onNavigationRequest: props.onNavigationRequest,
    isDesktop: props.isDesktop,
    locale: props.locale,
  };

  const headerProps = {
    ...props.header,
    menu: {
      icon: 'menu--large',
      label: 'Menu',
      labelled: 'after',
    },
    onHeaderMenuPress: props.onHeaderMenuPress,
    isDesktop: props.isDesktop,
    search: {
      handleSubmit: props.handleSubmit,
      fields: props.fields,
      onClearSearchPress: props.onClearSearchPress,
      onSearchBlur: props.onSearchBlur,
      onSearchFocus: props.onSearchFocus,
    },
    locale: props.locale,
  };

  // Attach the bag action to the bag tool
  // Replaced Array#find() with the lodash version to satisfy ie10/11 - this is a candidate for refactoring
  const bagTool = _.find(headerProps.toolbar.tools, tool => tool.id === 'Bag');
  const accountTool = _.find(
    headerProps.toolbar.tools,
    tool => tool.id === 'Account'
  );
  const target = props.layer.target;

  if (displayDrawer) {
    bagTool.onClick = props.onDrawerBagPress;
    // Have to set this as undefined here to ensure the popover bag is removed and the drawer bag displayed if the
    // browser is shrinking from desktop to mobile.
    // Edge case yes, but it has been flagged as a bug.
    bagTool.component = undefined;
  } else {
    bagTool.isVisible = props.popoverBag.isVisible;
    bagTool.onShow = props.onPopoverBagShow;
    bagTool.onHide = props.onPopoverBagHide;
    // rah: I initially just assigned <Bag /> without the closure, but that gave me
    // an entirely bizarre error to do with circular references when serialising
    // the state in Html.jsx. I have no idea why, but this fixes it. ¯\_(ツ)_/¯
    bagTool.component = () => (
      <PopoverBag
        onClose={props.onPopoverBagHide}
        onButtonClick={() => {
          props.onMegaMenuClose();
          props.onPopoverBagHide();
        }}
        onInteraction={props.cancelHideBag}
        isEmpty={!props.displayCheckoutLink}
      >
        <Bag locale={props.locale} location="miniBag" />
      </PopoverBag>
    );
  }

  headerProps.toolbar.tools = localiseTools(
    headerProps.toolbar.tools,
    props.intl.formatMessage
  );
  if (props.isAuthenticated && accountTool) {
    updateAuthLink(
      headerProps.toolbar.tools,
      props.isAuthenticated,
      props.onLogout,
      props.intl.formatMessage
    );
  }

  const drawerNavigationProps = {
    isVisible:
      (target === 'navigation' ||
        (!props.isDesktop &&
          props.locale.selected === 'en-US' &&
          (target === SEARCH || target === MY_ACCOUNT || target === SHOP))) &&
      props.layer.isVisible,
    items: props.navigation.items
      ? props.navigation.items.filter(navItem => !navItem.disabledMenuItem)
      : [],
    links: props.header.links,
    onClose: props.onDrawerNavigationClose,
    onNavigationRequest: props.onNavigationRequest,
    key: 'drawerNav',
    locale: props.locale,
    search: {
      handleSubmit: props.handleSubmit,
      fields: props.fields,
      onClearSearchPress: props.onClearSearchPress,
      onSearchBlur: props.onSearchBlur,
      onSearchFocus: props.onSearchFocus,
    },
    headerLinks: props.header.links,
    currentTab: target,
    myAccountTabLinks: props.header.toolbar.tools.find(
      tool => tool.id === 'Account'
    ).myAccountTabItems,
  };

  if (props.isDesktop) {
    drawerNavigationProps.toolbar = <Toolbar {...props.header.toolbar} />;
  }

  const drawerBagProps = {
    bag: <Bag location="miniBag" />,
    displayCheckoutLink: props.displayCheckoutLink,
    isVisible: target === 'bag' && props.layer.isVisible,
    onClose: props.onDrawerBagClose,
    key: 'drawerBag',
  };

  const megaMenuProps = {
    onClose: props.onMegaMenuClose,
    onBackButtonClick: props.onBackButtonClick,
    showContent: props.isDesktop,
    gridModifiers: props.isDesktop ? ['4up', 'centre'] : [],
    displayMegaMenuHeader: props.isMobile,
    locale: props.locale,
    isDesktop: props.isDesktop,
  };

  // If the mega menu contains shop by data, then add flag for displaying in an accordion or not.
  megaMenuProps.menus = props.megaMenu.map(menu =>
    !menu.shopBy
      ? menu
      : {
          ...menu,
          accordion: !props.isDesktop,
        }
  );

  if (drawerNavigationProps.isVisible) {
    bodyNavMenuId = 'navigation';
  }

  if (drawerBagProps.isVisible) {
    bodyNavMenuId = 'bag';
  }

  const displayContainers = !!(props.containers && props.containers.length);

  const { available, selected } = props.locale;

  const localeSwitcher = (
    <LocaleSwitcher
      target
      currency={props.currency}
      textVersion
      inDrawer={!props.isDesktop}
      placement={props.isDesktop ? 'below' : 'above'}
      className={
        props.isDesktop ? 'c-header__locale-switcher' : 'c-drawer-nav__label'
      }
      current={selected.split('-')[1].toLowerCase()}
      locales={available.map((locale: Locale) => ({
        locale: locale.locale,
        name: props.intl.formatMessage(
          localeLabels[locale.locale || 'default']
        ),
        path: locale.path,
        flagCode: locale.flagCode || locale.locale.split('-')[1].toLowerCase(),
      }))}
    />
  );

  const Item = (
    { children, link, size } // eslint-disable-line react/prop-types
  ) => (
    <a href={link}>
      <h1
        className={`u-text-size-${size}`}
        style={{
          color: 'white',
          'text-align': 'center',
          'text-transform': 'uppercase',
          'letter-spacing': '0.05em',
          padding: '5px',
        }}
      >
        {children}
      </h1>
    </a>
  );

  return (
    <BodyNav menuId={bodyNavMenuId}>
      <div
        className={`c-masthead ${
          props.locale.selected === 'en-US' ? 'c-masthead--usOnly' : ''
        }`}
      >
        {props.header.topBarText ? (
          <Carousel
            arrows={props.header.topBarText.length > 1 && props.isDesktop}
            dots={false}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={props.header.topBarText.length > 1}
            autoplaySpeed={4000}
            noPaddingForNavControls
            infinite
          >
            {topBarItems}
          </Carousel>
        ) : null}
        <div id="nav-section" className="c-masthead__navigation-section">
          <Header {...headerProps}>
            {!props.isMobile && props.locale.selected === 'en-GB'
              ? localeSwitcher
              : null}
          </Header>
          {!displayDrawer && <Navigation {...navigationProps} />}
          <MegaMenus {...megaMenuProps} />
          {displayDrawer && [
            <DrawerNavigation {...drawerNavigationProps}>
              {localeSwitcher}
            </DrawerNavigation>,
            <DrawerBag {...drawerBagProps} />,
          ]}
          {displayContainers && (
            <EditorialContainer containers={props.containers} />
          )}
        </div>
      </div>
    </BodyNav>
  );
};

/**
 * @todo hook up with website core to get the navigation items.
 *
 * @param {Object} store
 * @param {Object} props
 * @param {Object} req
 */
Masthead.populateStore = async store => {
  const content = await core.content.global.getNavigation();
  store.dispatch(navigationActions.receiveItems(content.navItems));
  store.dispatch(receiveLinks(content.headerLinks));
  store.dispatch(receiveTopBarText(content.topBarText));
  store.dispatch(megaMenuActions.receiveItems(content.menuItems));
  if (content.containers && content.containers.length) {
    store.dispatch(receiveContainers(content.containers));
  }
  const state = store.getState();
  const { isAuthenticated, user } = state.auth;
  if (isAuthenticated && user && user.id) {
    const savedItems = await core.savedItems.getSkuOrStyleColourCodes(user.id);
    store.dispatch(receiveSavedItemsList(savedItems));
  }

  updateTools(
    store.getState().app.locale,
    isAuthenticated,
    store.dispatch,
    store.getState().savedItems.itemCount,
    store.getState().bag.itemCount,
    store.getState().app.config.returnsPortal.enabled,
    store.getState().browser.deviceIs.desktop
  );
};

Masthead.propTypes = {
  app: PropTypes.object,
  auth: PropTypes.object,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  containers: PropTypes.array,
  layer: PropTypes.object.isRequired,
  intl: intlShape,
  displayCheckoutLink: PropTypes.bool,
  drawer: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  popoverBag: PropTypes.shape({
    isVisible: PropTypes.bool.isRequired,
  }).isRequired,
  megaMenu: PropTypes.array.isRequired,
  locale: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  onClearSearchPress: PropTypes.func.isRequired,
  onDrawerBagPress: PropTypes.func.isRequired,
  onDrawerBagClose: PropTypes.func.isRequired,
  onDrawerNavigationClose: PropTypes.func.isRequired,
  onHeaderMenuPress: PropTypes.func.isRequired,
  onNavigationRequest: PropTypes.func.isRequired,
  onMegaMenuClose: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onPopoverBagShow: PropTypes.func.isRequired,
  onPopoverBagHide: PropTypes.func.isRequired,
  cancelHideBag: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    searchPhrase: PropTypes.object.isRequired,
  }).isRequired,
  onSearchBlur: PropTypes.func.isRequired,
  onSearchFocus: PropTypes.func.isRequired,
  onSearchKeyUp: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onUpdateTools: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  savedItemsCount: PropTypes.number,
  bagItemsCount: PropTypes.number,
  isReturnsPortalEnabled: PropTypes.bool,
};

const formConfig = {
  form: 'searchForm',
  fields: ['searchPhrase'],
  onSubmit: ({ searchPhrase }, dispatch, props) => {
    if (!searchPhrase) return null;
    const cleanedSearchPhrase = searchPhrase.replace('@', '');
    const resultsPath = `${props.basePath}search/${cleanedSearchPhrase}`;
    debouncedSearch.cancel();
    return dispatch(
      navigationActions.apiProductSearch(searchPhrase, resultsPath)
    );
  },
};

const mapDispatchToProps = dispatch => ({
  onDrawerBagClose: () => dispatch(hideBag()),
  onDrawerBagPress: e => {
    e.preventDefault();
    dispatch(openLayer('bag'));
  },
  onDrawerNavigationClose: () => dispatch(closeLayer()),
  onHeaderMenuPress: tab =>
    dispatch(
      openLayer(
        typeof tab === 'string' || tab instanceof String ? tab : 'navigation'
      )
    ),
  onPopoverBagHide: () => dispatch(hideBag()),
  onPopoverBagShow: () => dispatch(openPopoverBag()),
  cancelHideBag: () => dispatch(cancelTimer(CLOSE_POPOVER_BAG_TIMER)),

  onNavigationRequest: (item, key, isDesktop) =>
    dispatch(onNavPress(key, isDesktop)),

  onClearSearchPress: () => dispatch(navigationActions.apiClearSearchHistory()),
  onSearchBlur: () => dispatch(navigationActions.searchClose()),
  onSearchFocus: () => {
    dispatch(navigationActions.searchOpen());
  },
  onSearchKeyUp: e => onSearchKeyUp(e, dispatch),
  onLogout: () => { dispatch(closeLayer()); dispatch(apiLogout()); },
  onMegaMenuClose: () => dispatch(megaMenuActions.closeMenuAndLayer()),
  onBackButtonClick: tab => {
    dispatch(megaMenuActions.closeMenuAndLayer());
    dispatch(openLayer(tab ? tab : 'navigation'));
  },
  onUpdateTools: (
    locale,
    isAuthenticated,
    savedItemsCount,
    bagItemsCount,
    isReturnsPortalEnabled,
    isDesktop
  ) =>
    updateTools(
      locale,
      isAuthenticated,
      dispatch,
      savedItemsCount,
      bagItemsCount,
      isReturnsPortalEnabled,
      isDesktop
    ),
});

const mapStateToProps = ({
  app: {
    config: { basePath, currency },
    locale,
    routing,
  },
  auth,
  browser: { deviceIs },
  layer,
  masthead,
  bag,
  savedItems,
  app,
}) => {
  let { pathname } = routing;
  const { search } = routing;
  // Remove the current base path from the pathname.
  // This will strip `/us/` from the pathname for example leaving us with the route pathname.
  // `/us/cookies` is now just `cookies/`
  pathname = pathname.replace(basePath, '');

  return {
    auth,
    app,
    currency,
    isDesktop: deviceIs.desktop,
    isMobile: deviceIs.mobile,
    layer,
    ...masthead,
    displayCheckoutLink: bag.items != null && !!bag.items.length,
    isAuthenticated: auth != null && (auth.isAuthenticated || false),
    basePath: app.config.basePath,
    savedItemsCount: savedItems.itemCount,
    bagItemsCount: bag.itemCount,
    isReturnsPortalEnabled: app.config.returnsPortal.enabled,
    locale: {
      ..._.omit(locale, ['translations']),
      available: locale.available.map(l => ({
        ...l,
        // Attach the query string params to the path.
        path: `${l.url}${pathname}${search}`,
      })),
    },
  };
};

const WrappedMasthead = injectIntl(
  reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(Masthead)
);

// Moving populate store to the top level so it can be called in App.jsx
// without having to access WrappedComponent property
WrappedMasthead.populateStore = WrappedMasthead.WrappedComponent.populateStore;

export default WrappedMasthead;

export const UnwrappedMasthead = Masthead;
